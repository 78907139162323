import React from "react"
import Foto from "@components/pageCasosdeExito/image/imgPhotoEmpresa/escampa/img1.png"
import Foto2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/escampa/img2.png"

//logo de empresas clientes
import Limautos from "@components/pageCasosdeExito/image/logoscustomer/Escampa.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import chatbot from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/escampa/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/escampa/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/escampa/img3.svg"

const text1 = (
  <span className="">
    Escampa, empresa mexicana que brinda soluciones financieras para aliviar el sobreendeudamiento en México, con préstamos, consolidación de deudas y mejora crediticia; cuenta con más de 20 años de experiencia en el sector financiero y tiene prácticas de gestión adecuadas al cumplimiento de todas las regulaciones contables del país.
    <br /> <br />
    Con la implementación de Beex y la API de WhatsApp Business, Escampa ha logrado automatizar su atención al cliente y mantiene un contacto con más de diez mil personas a través de este canal, logrando mejorar en +30% su CSAT.
  </span>
)

const text2 = (
  <span>
    Gracias a sus estrictos protocolos de seguridad para proteger la información personal y financiera de sus usuarios, Escampa se ha posicionado como una plataforma confiable en préstamos digitales a personas naturales. Gabriel Gmora Backal, socio fundador de Escampa, ha sido el contacto directo entre la empresa y Beex para acceder rápidamente a la API de WhatsApp Business e implementar las mejoras en el canal mediante la automatización en la atención al cliente y la creación de campañas masivas.
    <br /> <br />
    Parte de sus objetivos es seguir reforzando la seguridad en sus canales digitales. Por ello, vieron en Beex la solución más confiable para validar su cuenta de WhatsApp mediante su API. Todo esto ayuda a reforzar aún más la confianza con sus usuarios.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex nos permite mantener contacto con decenas de miles de personas a través de WhatsApp. Al estar validados en este canal, nuestros usuarios confían más en nuestro servicio, lo que mejora significativamente la experiencia del cliente.”
  </p>
)

const text4 = ""

const text5 = (
  <span>
    WhatsApp se ha convertido en el principal canal de contacto de Escampa gracias a la implementación de la API de WhatsApp Business proporcionada por Beex. Esta herramienta ha permitido automatizar la atención al cliente, reduciendo tiempos de respuesta y mejorando la eficiencia operativa.
    <br /> <br />
    Además, el uso de plantillas de mensaje para WhatsApp ha facilitado la gestión del envío de campañas masivas y ha asegurado la consistencia en la comunicación.
    <br /> <br />
    Como resultado, Escampa ha logrado mantener una interacción fluida y personalizada con sus clientes potenciales, incrementando la satisfacción y optimizando sus procesos de atención.
  </span>
)
const text6 = (
  <span>
    “Esta versión oficial de WhatsApp para el sector financiero ha sido fundamental para nosotros. Nos ha permitido ofrecer un servicio más seguro y confiable a nuestros clientes, cumpliendo con los estándares regulatorios mientras mejoramos la comunicación. Esto, ha hecho que mejoremos en +30% nuestro CSAT.”
  </span>
)

const text7 = (
  <span>
    Las campañas masivas de WhatsApp permiten a Escampa contactar a los usuarios de manera más rápida. Con Beex y la API de WhatsApp Business, la empresa puede diseñar y programar campañas según las segmentaciones necesarias, ya sea para comunicaciones urgentes o especiales.
    <br /> <br />
    Uno de los puntos más importantes es la capacidad de segmentar adecuadamente a sus clientes. Esta segmentación se realiza basándose en diversas propiedades. La simplicidad y efectividad de Beex ha permitido mantener una comunicación directa y personalizada con sus clientes.
    <br /> <br />
    Esta estrategia no solo mejora la experiencia del cliente, sino que también aumenta la eficiencia operativa de Escampa, fortaleciendo la capacidad para mantener una comunicación directa y efectiva.
  </span>
)
const text8 = (
  <span>
    “Las campañas masivas nos permiten contactar a los usuarios de manera más rápida y efectiva, lo que es crucial para comunicaciones urgentes o especiales. Hemos observado mejoras en la tasa de respuesta y en la efectividad de nuestras campañas de comunicación directa.”
  </span>
)

const text9 = (
  <span>
    En Beex, valoramos profundamente el acompañamiento y soporte a nuestros clientes, y con Escampa esto fue evidente desde el principio. La colaboración estrecha entre ambos equipos, junto con el apoyo continuo del equipo de Customer Success asignado a Escampa, resultó en un proceso de implementación exitoso y satisfactorio de nuestra solución.
    <br /> <br />
    Escampa destaca el excelente soporte recibido de parte de Beex. Según Gabriel, nuestro equipo no solo está siempre disponible para ayudar, sino que también es proactivo en ofrecer soluciones y mejoras.
    <br /> <br />
    Tras la implementación, Escampa ha experimentado una notable transformación en la manera en que interactúan y atienden a sus clientes. Además, valora la fiabilidad y efectividad de nuestras soluciones, considerándolas una inversión que vale la pena por la calidad del servicio recibido.
  </span>
)
const text10 = (
  <span>
    “El soporte y acompañamiento que hemos recibido de Beex, ha sido excelente. La atención y respuesta a nuestras necesidades han sido muy satisfactorias. Recomendaría las soluciones de Beex por la alta calidad de su plataforma y servicio.”
  </span>
)

const text11 = (
  <span>
    Estos son algunos procesos con los que Beex ayuda a Escampa en su operación de atención al cliente:
  </span>
)

const text12 = (
  <span>
    En Beex, estamos orgullosos de apoyar a Escampa, una empresa líder en su sector. Nuestra tecnología omnicanal ha mejorado significativamente su atención al cliente, optimizando la comunicación y personalización. Esta colaboración nos brinda valiosos insights para perfeccionar nuestras soluciones.
  </span>
)

const data = {
  intro: {
    image: Limautos,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: Foto,
    text4: text4,
    title5: "Uso de la API de WhatsApp Business en el sector",
    text5: text5,
    text6: text6,
    title7: "Campañas masivas de WhatsApp",
    text7: text7,
    text8: text8,
    fototwo: Foto2,
    title9: "Experiencia con el equipo de soporte",
    text9: text9,
    title10: "Acompañamiento y respaldo que se siente",
    text10: text10,
    text11: text11,
    text12: text12,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "WhatsApp se ha convertido en una herramienta esencial para Escampa. La implementación de la API de WhatsApp Business ha permitido una comunicación más rápida y efectiva con los clientes, mejorando la atención al cliente y agilizando las respuestas a consultas.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Las campañas masivas de WhatsApp han revolucionado la comunicación de Escampa. Gracias a la segmentación precisa y al uso de plantillas, pueden enviar mensajes personalizados a grandes audiencias rápidamente, mejorando la tasa de respuesta.",
    },
    {
      title: "Chatbot",
      img: chatbot,
      info:
        "Con la tecnología de Beex, han logrado automatizar la atención al cliente, lo que ha reducido los tiempos de respuesta y ha optimizado la gestión de consultas y solicitudes.",
    },
  ],
}

export default data
